import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import LandingPage from "../pages/LandingPage";
import TermsAndConditions from "../pages/TermsAndConditions";
import SalikInfo from "../pages/SalikInfo";
import FuelPolicy from "../pages/FuelPolicy";
import TrafficRegulations from "../pages/TrafficRegulations";
import EligibilityToRent from "../pages/EligibilityToRent";
const Routers = () => {
  return (
    <Routes>
      <Route path="/home" element={<Navigate to="/" />} />
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/our-fleet" element={<CarListing />} />
      <Route path="/our-fleet/:slug" element={<CarDetails />} />
      <Route path="/our-blogs" element={<Blog />} />
      <Route path="/our-blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact-us" element={<Contact />} />
            <Route path="/book-your-car" element={<LandingPage/> }/>
            <Route path="/terms-and-conditions" element={<TermsAndConditions/> }/>
            <Route path="/salik" element={<SalikInfo/> }/>
            <Route path="/fuel-policy" element={<FuelPolicy/> }/>
            <Route path="/traffic-rules" element={<TrafficRegulations/> }/>
            <Route path="/eligibility-to-rent" element={<EligibilityToRent />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
