import React from "react";
import { Container, Card, ListGroup } from "react-bootstrap";

const TrafficRegulations = () => {
  return (
    <Container className="my-4">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <h2>Traffic Regulations</h2>
          </Card.Title>
          <Card.Text>
            <p>
              The UAE has severe traffic rules and regulations that must be followed at all times. Failure to comply with these laws results in substantial penalties and fines. Some of the key traffic laws in Dubai are as follows:
            </p>
          </Card.Text>
          <ListGroup className="mb-4">
            <ListGroup.Item>
              Stay under the <strong>60-80 km/h speed limit</strong> in urban areas, and <strong>100-120 km/h speed limit</strong> on highways.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Tailgating</strong> is taken very seriously. Fines include AED 400, four black points on your license, or car impoundment for 15 days.
            </ListGroup.Item>
            <ListGroup.Item>
              Only enter a yellow box if your exit is clear. Otherwise, it is considered an offense, and you risk a fine of AED 500.
            </ListGroup.Item>
            <ListGroup.Item>
              Don’t drink and drive, as there is a <strong>zero-tolerance policy</strong> for drunk driving. Consequences include hefty fines and deportation.
            </ListGroup.Item>
            <ListGroup.Item>Park only in designated parking areas.</ListGroup.Item>
          </ListGroup>
          <Card.Text>
            <p>
              If you incur any fines whilst renting from us, you will be held fully responsible for covering the cost of the fine. We are happy to navigate the administrative procedure of paying these dues for you for an additional <strong>12% fee per penalty incurred</strong>.
            </p>
            <p>
              Please note that Rapid Rentals will only be able to charge you this fee once we receive official details from the authorities, as these details typically take some time to process.
            </p>
            <p className="text-danger">
              Rapid Rentals is not responsible for any errors or delays caused by misinformation or delays from the authorities.
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TrafficRegulations;
