import React from "react";
import { Container, Card, ListGroup, Table } from "react-bootstrap";

const EligibilityToRent = () => {
  return (
    <Container className="my-4">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <h2>Eligibility to Rent</h2>
          </Card.Title>

          <Card.Subtitle className="mb-3">
            <h5>Passports</h5>
          </Card.Subtitle>
          <Card.Text>
            <p>
              All UAE residents must provide a passport copy (both photo ID page and visa page) when choosing to rent. Additionally, both an entry visa and a passport are required for any tourist or visitor to the UAE. When proceeding with your booking, Rapid Rentals will request both your passport and visa copies to be shared for documentation purposes to avoid any potential complexities.
            </p>
          </Card.Text>

          <Card.Subtitle className="mb-3">
            <h5>Payment Methods</h5>
          </Card.Subtitle>
          <Card.Text>
            <p>
              The renter must have a valid credit card issued in their name. The estimated rental charges are taken upfront at the time of vehicle pick-up, and a pre-authorization will be ascertained as per your contract. The card must have an existing balance that enables you to cover all your dues with us. Any security deposits, if applicable, will depend on your choice of vehicle for rent.
            </p>
            <p>
              <strong>Note:</strong> While debit cards can be used for payments, security deposit holds cannot be placed on them.
            </p>
            <p>We accept the following credit cards:</p>
            <ListGroup>
              <ListGroup.Item>Visa</ListGroup.Item>
              <ListGroup.Item>MasterCard</ListGroup.Item>
              <ListGroup.Item>American Express</ListGroup.Item>
              <ListGroup.Item>Diners Club</ListGroup.Item>
            </ListGroup>
          </Card.Text>

          <Card.Subtitle className="mb-3">
            <h5>Driver’s License</h5>
          </Card.Subtitle>
          <Card.Text>
            <p>
              <strong>Residents:</strong> A valid driver’s license issued by the UAE authorities, with a minimum holding period of one year. A residence or employment visa issued by the UAE authorities is also required.
            </p>
            <p>
              <strong>Non-Residents:</strong> Tourists or visitors to the UAE are eligible to drive using their foreign driving licenses alongside a valid visit visa. The original license must be issued by the same country as the customer's residence visa.
            </p>
          </Card.Text>

          <Card.Subtitle className="mb-3">
            <h5>Eligible Countries</h5>
          </Card.Subtitle>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Region</th>
                <th>Countries</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gulf Cooperation Council</td>
                <td>Qatar, Oman, Bahrain, Kuwait, Kingdom of Saudi Arabia</td>
              </tr>
              <tr>
                <td>Asian Countries</td>
                <td>
                  China, Japan, South Korea, Australia, Singapore, Hong Kong, South Africa, New Zealand
                </td>
              </tr>
              <tr>
                <td>North American Countries</td>
                <td>United States of America, Canada</td>
              </tr>
              <tr>
                <td>European Countries</td>
                <td>
                  Germany, Greece, France, Italy, Sweden, United Kingdom, Switzerland, Belgium, Norway, Poland, Ireland, Romania, Slovakia, Turkey, Luxemburg, Finland, Denmark, Spain, Portugal, Netherlands, Austria, Cyprus, Holland, Iceland, Hungary, Bulgaria, Serbia, Lithuania, Latvia, The Republic of Montenegro
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EligibilityToRent;
