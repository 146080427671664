import React from "react";
import { Container, Card, Table } from "react-bootstrap";

const SalikInfo = () => {
  return (
    <Container className="my-4">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <h2>Salik Information</h2>
          </Card.Title>
          <Card.Text>
            <p>
              The following data is accurate and updated as per the time of its publishing. Rapid Rentals is not liable for any information that has been omitted due to later updates issued by the authorities.
            </p>
            <p>
              <strong>Salik</strong> is an electronic tax collection system that is used in the UAE, primarily Dubai, to charge drivers a fee for using specific roads. This system is in place to prevent traffic congestion and preserve the road network and infrastructure of the city.
            </p>
            <p>
              There is no limitation based on hours of operation as Salik is charged 24/7. There must be a Salik tag fixed to the windshield of the vehicle to enable the automated toll collection system. Upon the culmination of your rental duration or renewal, depending on your requirements and needs, we will let you know the number of Salik crossings made by you for which you will be charged accordingly.
            </p>
            <p>
              <strong>A fee of AED 5 (including VAT) is charged for each passing.</strong>
            </p>
          </Card.Text>
          <Card.Subtitle className="mb-3"><strong>Major Passings in Dubai:</strong></Card.Subtitle>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Al Barsha</td>
              </tr>
              <tr>
                <td>Al Garhoud</td>
              </tr>
              <tr>
                <td>Al Maktoum Bridge</td>
              </tr>
              <tr>
                <td>Al Safa</td>
              </tr>
              <tr>
                <td>Jebel Ali/E311</td>
              </tr>
              <tr>
                <td>Al Maktoum</td>
              </tr>
              <tr>
                <td>Al Mamzar South</td>
              </tr>
              <tr>
                <td>Airport Tunnel Gate</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SalikInfo;
