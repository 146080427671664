import React from "react";
import { Container, Card } from "react-bootstrap";

const FuelPolicy = () => {
  return (
    <Container className="my-4">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <h2>Fuel Policy</h2>
          </Card.Title>
          <Card.Text>
            <p>
              Rapid Rentals offers its customers a <strong>Prepaid Fuel Option</strong>. Choosing this option allows you to purchase a full tank of fuel upfront upon renting a car from us. This policy enables you to return the car with any fuel level that you wish. 
            </p>
            <p>
              <strong>Note:</strong> There will be no refund in case of there being unused fuel in the car.
            </p>
            <p>
              If you do not wish to rent a car with the Prepaid Fuel Option, you will be liable to return the car with a full tank of fuel upon the end of your rental period.
            </p>
            <p className="text-danger">
              <strong>Failure to return the car with a full tank will result in additional charges.</strong>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FuelPolicy;
