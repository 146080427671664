import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">Terms and Conditions</h1>
      <div className="mb-4">
        <p>
        These Terms and Conditions are originally written in English. We may accommodate requests for 
        translated versions to be provided; however, the English version will qualify as
         the authoritative text in case of any discrepancies or issues of translation.
        </p>
      </div>

      <div className="mb-4">

        <p>
      1.  Our terms and conditions are overseen by and developed in accordance with both the laws of 
        the United Arab Emirates as well as the local laws of the Emirate of business operations.
         In case of any dispute between the two parties,
         the same shall be submitted to the relevant court of law.        </p>

      </div>

      <div className="mb-4">
        <p> 2. Unless fraudulent activity is suspected, Rapid Rentals is not to be held accountable for any damages experienced by the renter or any third party. The Renter agrees to protect Rapid Rentals alongside all its associated stakeholders from any costs, claims, losses, expenses, or
           liabilities that may arise following any contractual violations or driving of the rented vehicle.</p>
      </div>
      <div className=' mb-4'>
      3. The list of required documents at the time of rental is as follows: <br />
Credit card issued under the renter’s name.
<br />
Valid Passport 
<br />
Valid Emirates ID
<br />
Valid Driver’s License issued by the UAE authorities, with a minimum holding period of a year.
<br />
Residence or employment visa issued by the UAE authorities. 
<br />
Valid International Driving License if applicable. 

      </div>
     <p className=' mb-4'>4. Rapid Rentals ensures that the renter will receive the specified make and model within select car categories. If the desired categories are not available when you arrive, you become eligible for an upgrade to vehicles available in our fleet at the time of your rental. This upgrade may be replaced with your required car if and when it is available during your rental duration. Please know that while we can guarantee the car make and models,
       this does not extend to aesthetic elements such as color of the car or other amenities within it. </p>
<p className='mb-4'>
  5. Refunds for cancellation of bookings will be processed to the same credit card used for the original payment within 30 working days of the cancellation request. If a cancellation is requested within 30 days of the pick-up date, the refund will be processed within 30 working days from the cancellation request. If the refund cannot be processed due to an invalid credit card, the renter must claim the refund within six months from the vehicle's return. Refunds for any other claims, including overcharges or adjustments after the rental ends, will be processed within 30 working days from the end of the rental period. All outstanding fees or charges owed to Rapid Rentals will be deducted from the refund before it is issued. Refunds should be claimed within 30 days from the pick-up date. Any claims made after this period may not be eligible for a refund. Please bear in mind that early returns of prepaid bookings are non-refundable. 
</p>
<p className='mb-4'>
  6. An essential requirement of renting with us is a credit card registered in your name. Payments are accepted using Visa, American Express, and MasterCard. There are no cancellation or no-show fees for bookings with Rapid Rentals at this time. The renter can initiate online payments to make their reservations and can cancel as per their own convenience. Cancellations can be made by contacting us on +971 55 865 4999. 
</p>
<p className='mb-4'>
  7. Rapid Rentals acknowledges the confidentiality of your personal information and ensures that your data is neither retained in our records nor shared with external parties, except when required by law. In such cases, the renter authorizes Rapid Rentals to disclose personal data to relevant authorities, including but not limited to the police, traffic departments, and other appropriate entities.
</p>
<p className='mb-4'>
  8. Rates are subject to change after the date of the reservation given that changes are made to the booking. If the renter wishes to modify the agreed upon duration of rental, the renter acknowledges that Rapid Rentals reserves the right to change its rates as per the newly conceived agreement. 
</p>
<p className='mb-4'>
  9. With the commencement of the rental duration, the renter agrees to the vehicle being in the condition detailed on the Vehicle Check Report. Once the vehicle is taken by the renter, they are fully held responsible and liable for any damage incurred to the vehicle. Upon vehicle return, it is inspected and checked in by a representative of Rapid Rentals and documented accordingly. The renter has to deliver on the expectation of returning the vehicle in the very same condition as received by them at the beginning of their rental duration alongside any additional parts for example spare tires at the agreed upon location, date, and time of the vehicle return as stated in the agreement. 

</p>
<p className='mb-4'>
  10. Rapid Rentals reserves the right to recall the vehicle at any time in the event of payment obligations not being met, dues remaining unsettled, or if it is discovered that the vehicle is being used in violation of the terms and conditions of the rental agreement. Please be advised that in such cases, Rapid Rentals also retains the right to do any of the following: <br />
Repossess the vehicle without notice
<br />
Report the Vehicle as stolen to the authorities
<br />
File a police report for non-payment of outstanding fees
<br />
Charge the Renter a late fee of 2% on unpaid installments, along with any additional costs incurred, including recovery fees, damages, or other expenses, until the outstanding balance is fully settled.

</p>
<p className='mb-4'>
  11. Given that the renter returns the vehicle outside of the location’s operating hours, they must follow the out-of-hours return procedures provided by Rapid Rentals for that specific location. In any case, the renter remains fully liable for the vehicle until the location reopens and the vehicle has been officially checked in by us. 
</p>
<p className='mb-4'>
  12. If Rapid Rentals and the renter agree on the renter returning the vehicle at a foreign location other than the rental location, the renter remains fully responsible for the Vehicle until it is collected by Rapid Rentals. A document has to be obtained from the renter as proof of confirmation of vehicle return to Rapid Rentals. 

</p>


<p className='mb-4'>
  13. Rapid Rentals is not to be held responsible for any loss or damage incurred to property or articles accidentally left in the vehicle, regardless of whether the property was left in the vehicle during or after the culmination of rental duration. Any personal belongings left in the vehicle are entirely at the renter's risk for the duration of the rental.
</p>
<p className='mb-4'>
  14. If the rented vehicle needs to be replaced due to any obligations such as servicing, the renter will be liable to return the vehicle upon Rapid Rental’s request. Rapid Rentals does not provide a guarantee that the very same vehicle will be returned to the renter. If service schedules or any such obligations are delayed because the vehicle is returned late, the renter will be held responsible for any damages or costs incurred due to the delay of the return.
</p>
<p className='mb-4'>
  15. If the specific vehicle requested by the Renter is unavailable at the time of rental commencement, Rapid Rentals may offer a temporary upgrade in accordance with the renter’s wishes. Once the originally requested vehicle is available, Rapid Rentals will initiate contact with the renter for exchange arrangements. The renter will be required to return the temporary upgrade to Rapid Rentals in this case. Contact will be initiated for an exchange thrice. Given that all three contact attempts fail, the renter will be charged for the vehicle initially issued for the entirety of their rental duration.
</p>

<p className='mb-4'>
  16. If the Renter terminates a monthly contract early, a higher rate will apply based on the days of usage of the vehicle issued.
</p>

<p className='mb-4'>
  17. The minimum rental duration comprises 24 hours with there being a grace period of 48 hours following vehicle return date. Please note that in case of late vehicle return, additional charges as per time elapsed will be applied accordingly. 
</p>
<p className='mb-4'>
  18. Any additional requests for extensions should be communicated in advance. In the event of there being no prior communication and the failure of vehicle return at the predetermined return date as per the agreement, the renter’s contract will be considered automatically renewed with the relevant payment amount being deducted from your original mode of payment with us. 
</p>
<p className='mb-4'>
  19. Rapid Rentals outlines the following conditions of use for their vehicles:  <br />
The issued vehicle must not be used for any business or commercial purposes such as for the transportation of multiple passengers and their properties, or capital for hire. <br />
The issued vehicle must not be employed to transport more passengers than its capacity.<br />
The issued vehicle must not be used to carry anything that can potentially enact harm to it or impact Rapid Rentals ability to rent it out again. <br />
The issued vehicle must not be used to tow any other vehicle. <br />
The issued vehicle must not be used for any illegal purposes such as to participate in races. <br />
The issued vehicle must not be driven on unpaved surfaces or unstable terrain. <br />
Under no circumstances, must the issued vehicle be driven under the influence of any substances known for impacting and impairing attentiveness and consciousness such as hallucinogens, alcohol, or narcotics etc. <br />
The renter must not violate any effective rules and regulations or traffic laws in place. <br />
The issued vehicle must not be driven in restricted locations as per the rental contract or outside the country unless prior approval from Rapid Rentals is obtained and all necessary documents submitted. <br />
The issued vehicle must not be driven by any third party unless the third party has been notified to Rapid Rentals at the time of rental and is mentioned in the rental contract. <br />
The issued vehicle cannot be used for sub-leasing purposes. <br />

</p>
<p className='mb-4'>
  20. If the vehicle is damaged, lost, or stolen, or any parts of it are lost or damaged, the renter will be held responsible for covering all resultant costs. This includes expenses for complete replacement or recovery of the vehicle, repair costs, any losses adversely impacting the vehicle’s value, as well as the loss of rental income, regardless of whether the renter or driver is directly at fault.
</p>
<p className='mb-4'>
  21. The Renter is entirely accountable for any damage to the Vehicle, particularly if it is caused by:
  <br />
Using the wrong or contaminated fuel <br />
Driving through floodwaters <br />
Water damage to the vehicle <br />
Driving on unstable terrain such as sea beaches, rocky deserts, sand dunes, or any off-road locations. 
<br />
</p>
<p className='mb-4'>
22. If the Vehicle is unavailable for rental due to damage incurred from the renter’s end, Rapid Rentals may charge compensation equivalent to the loss of rental revenue until the vehicle, or a replacement, is returned in the same condition as originally received.
</p>

<p className='mb-4'>
  23. In the event of an accident (whether or not there’s damage to the vehicle), personal injury, or theft of the vehicle or its parts, the renter must immediately notify Rapid Rentals and the authorities to obtain an official Police Report. The renter must provide all required documents and follow the necessary procedures as directed by the police in this case. A copy of the police report must be sent to Rapid Rentals. If the renter fails to provide the report within a reasonable period of time, the Collision Damage Waiver (CDW) and any other insurance coverage will be voided. Thus, the renter will be fully held responsible for all costs related to the vehicle damages in the aftermath of the accident.

</p>

<p className='mb-4'>
  24. The renter must not take actions that would invalidate the insurance coverage, especially admitting liability to any third parties. If such an admission is made, the renter will bear full responsibility for compensating any such third-party claims.
</p>
<p className='mb-4'>
  25. The renter agrees to indemnify Rapid Rentals fully in cases where the insurance company rejects a claim due to the renter's failure to follow the terms. This also applies to cases where a third party sues Rapid Rentals because of the renter's actions or misconduct. The renter is also liable to assist Rapid Rentals and its insurers in any claims or legal matters related to an accident or damage to the vehicle. This also applies to cases of vehicle theft. 
</p>
<p className='mb-4'>
  26. If the Police Report or repair documentation does not identify the guilty party in an accident, the renter will be held responsible for all the costs incurred by Rapid Rentals, including any excess liability amounts as per the renter’s chosen insurance coverage (if applicable).

</p>
<p className='mb-4'>
  27. Any damages incurred on the vehicle windows or tires is not coverable under insurance and the renter will be directly held responsible to pay the costs for damage and replacements. 

</p>
<p className='mb-4'>
  28. No services or repairs can be performed on the vehicle without informing in advance and obtaining approval in writing from Rapid Rentals. 
</p>
<p className='mb-4'> 29. Any failures to comply with and honor the terms of the agreement may result in the cancellation of the insurance policy. In such a case, the Renter will be liable for all costs, damages, and losses that Rapid Rentals is subjected to as a consequence of the renter's actions and failure to comply.
</p>
<p className='mb-4'>
30. Rapid Rentals retains the right to change and make amendments to all its policies without prior notice. These policies are developed with the consideration of informational purposes only and must not be used for the renunciation for any rights of Rapid Rentals which are all reserved by the organization.  
</p>
<p className='mb-4'>
  31. In accordance with monthly rentals, there is a predetermined mileage allowance determined at the time of rental. Any additional or excessive mileage going over the predetermined limit will be charged at AED 0.30 to AED 0.50 per additional km, depending on the vehicle rented and as per your rental contract. 
</p>
<p className='mb-4'>
  32. Given that a Salik charge or any other financial penalties are incurred from authorities such as the police or the traffic department during the rental duration, Rapid Rentals reserves the right to charge the relevant amounts to the renter’s mode of payment. Salik is charged at AED 5 per crossing. 

</p>
<p className='mb-4'>
33. A 12% service fee will be charged per offense, penalty, or violation incurred by the renter. If the driver commits an offense that requires the authorities to impound the vehicle, Rapid Rentals reserves the right to make sure the required fees are paid for the avoidance of any such impounding. The renter will be held responsible for covering such a fee to prevent the impounding. Any additional charges incurred within 30 days after the agreement ends will be automatically charged to the customer’s credit card, without prior notice.
</p>
<p className='mb-4'>
34. In circumstances wherein the renter enlists Rapid Rentals’s assistance such as obtaining document translations, Final Police Reports, traffic fines evidence, preventing vehicle impounding etc, additional administrative charges apply.  </p>
 
 <p className='mb-4'>
 35. Cleaning service fees will be charged from the renter in the event wherein the vehicle is returned in a condition that requires cleaning. 
 </p> 
  <p className='mb-4'>
 36. Additional charges will be charged to the renter for roadside assistance in the event wherein the vehicle needs to be recovered, towed, or pulled to a workshop due to negligence on behalf of the renter. 
 </p>
  <p className='mb-4'>
  37. The renter is expected to honor the rental rates and additional charges incurred throughout their rental duration. If the renter fails to return the vehicle at the agreed upon time of vehicle return as per the contract or if they wish to renew their existing rental agreement with Rapid Rentals, they will be charged in accordance with the rates effective presently at the time. 

 </p>
  <p className='mb-4'>
  38. The renter recognizes that Rapid Rentals reserves the right to debit any payments or charges incurred from the renter’s mode of payment. 
 </p>
  <p className='mb-4'>
 39. Rapid Rentals offers standard insurance coverage for individuals using the vehicle under an automobile liability policy. The renter does have the option to purchase additional insurance coverage by paying the applicable premium/rate, as agreed with Rapid Rentals. If the renter opts to do this, they must obtain an official signed copy of the Terms and Conditions for any extended insurance or have the enhanced coverage explicitly stated in their contract (if it exceeds the standard coverage already provided). If this is not done, no additional insurance coverage will be considered valid.<br />
<br />
<p className='mb-4'>
In the case of the application of the ‘Collision Damage Waiver’ (CDW), the renter accepts all liability for any damages and losses to the vehicle or vehicle parts. This liability is limited to the non-waivable and non-refundable excess amount, as specified within this Agreement. In addition to this, the renter is responsible for providing Rapid Rentals with a valid accident report from the police or another relevant authority. Failure to do so will result in the renter being solely responsible for all costs associated with restoring and returning the vehicle to Rapid Rentals in its original condition.  <br /> 

</p>
<p className='mb-4'>
If additional insurance is purchased for use of the vehicle outside the UAE, it can only be utilized to cover damage claims and not reimbursements for any other expenses incurred. The insurance will only be regarded upon the submission of all required documents to support the claim. <br />
</p>
<p className='mb-4'>The renter has the option to accept Personal Accident Insurance (PAI) by paying the daily premium specified in this Agreement as agreed with Rapid Rentals. This insurance can be utilized to cover all medical expenses and accidental death for the renter and passengers in case of an accident during the rental duration in accordance with the Vehicle Insurance Policy of Rapid Rentals. <br />
</p>If the Renter agrees to the optional Super Collision Damage Waiver (SCDW), this will entail the renter will have to pay the specified premium in advance, as outlined in their rental contract. With the SCDW, the renter is relieved of liability for damage to or loss of the vehicle, its parts, and accessories with the exception of cases of theft. Given that a valid Police Report is submitted and the insurance company approves the claim. Please acknowledge that the coverage will not be considered in the absence of a permanent Police Report. 


 </p>
    </div>
  );
};

export default TermsAndConditions;
